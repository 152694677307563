import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="kronickle--split-pdf"
export default class extends Controller {
  static values = {
    assetUrl: String,
  }

  static targets = [
    "splitPdfContainer",
    "page",
  ]

  initialize() {
    this.splittedPages = {}

    this.handleAssetDeleted = this.#handleAssetDeleted.bind(this)
    this.handlePdfPagesReady = this.#handlePdfPagesReady.bind(this)
  }

  connect() {
    document.addEventListener("kronickle--asset-deleted", this.handleAssetDeleted)
    document.addEventListener("kronickle--split-pdf-pages-ready", this.handlePdfPagesReady)
  }

  disconnect() {
    document.removeEventListener("kronickle--asset-deleted", this.handleAssetDeleted)
    document.removeEventListener("kronickle--split-pdf-pages-ready", this.handlePdfPagesReady)
  }

  async submit(event) {
    event.preventDefault();
    const form = event.target.form;
    const formData = new FormData(form);
    const saveButton = event.target;

    const currentSplittedPages = formData.getAll('split_pdf_selected_pages[]')
    this.#toggleSaveButton(saveButton, true, 'Splitting...')
    this.#hideSelectedPages()

    const response = await this.#makeRequest('POST', form.action, formData)

    if (response.ok) {
      const responseHtml = this.#parseHtmlResponse(await response.html)

      const uuid = responseHtml.dataset.id;
      this.splittedPages[uuid] = currentSplittedPages

      const panelItem = form.closest('.Panel__item');
      panelItem.insertAdjacentElement('afterend', responseHtml);

      APP.executeCallback(responseHtml);
      APP.callbackFunctions.setupAssetUploadCancel(responseHtml);

      this.#toggleSaveButton(saveButton, false, 'Split PDF')

      this.#scrollToElement(responseHtml)
    }
  }

  done (doneEvent) {
    doneEvent.preventDefault();

    const dialog = document.getElementById('turbo-confirm');
    const modal = new APP.Modal(dialog).open();
    const confirmButton = dialog.querySelector('.js-confirmation-dialog-confirm');

    const handleConfirmationClick = async (clickEvent) => {
      clickEvent.preventDefault();

      const response = await this.#makeRequest('DELETE', this.assetUrlValue)

      if (response.ok) {
        modal.close();
        this.element.remove();
      }
    };

    confirmButton.removeEventListener('click', handleConfirmationClick);
    confirmButton.addEventListener('click', handleConfirmationClick);
  }

  async #handlePdfPagesReady() {
    const response = await this.#makeRequest('GET', this.assetUrlValue)

    if (response.ok) {
      const jsonResponse = await response.json
      this.splitPdfContainerTarget.innerHTML = jsonResponse.split_pdf
    } else {
      console.error('Failed to fetch PDF pages')
    }
  }

  #handleAssetDeleted(event) {
    const uuid = event.detail.uuid
    if (this.splittedPages[uuid]) {
      this.splittedPages[uuid].forEach((pageNumber) => {
        this.pageTargets.forEach((target) => {
          const checkbox = target.querySelector('input[type="checkbox"]')
          if (checkbox.value == pageNumber) {
            target.classList.remove("hidden", "selected")
          }
        })
      })
      delete this.splittedPages[uuid]
      this.element.scrollIntoView({ behavior: "smooth" })
    }
  }

  handlePageSelection(event) {
    this.pageTargets.forEach((target) => {
      const checkbox = target.querySelector('input[type="checkbox"]');
      if (checkbox === event.target) {
        target.classList.toggle('selected', checkbox.checked);
      }
    });
  }

  #hideSelectedPages() {
    this.pageTargets.forEach((target) => {
      const checkbox = target.querySelector('input[type="checkbox"]')
      if (checkbox.checked) {
        checkbox.checked = false
        target.classList.add("hidden")
        target.classList.remove("selected")
      }
    })
  }

  #toggleSaveButton(button, disabled, text) {
    button.disabled = disabled;
    button.textContent = text;
  }

  #scrollToElement(element) {
    const scrollTopValue = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: scrollTopValue,
      behavior: 'smooth',
    });
  }

  #parseHtmlResponse(htmlString) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.firstElementChild;
  }

  async #makeRequest(method = "GET", url, body = null) {
    const request = new FetchRequest(method, url, { body, responseKind: "html" })
    return request.perform()
  }
}
